<template>
  <v-container class="filters" :style="{'background-color': $vuetify.theme.dark ? '#111' : '#ffffff'}">
    <v-row>
      <v-col cols="12">
        <v-text-field light prepend-inner-icon="search" placeholder="Search" solo hide-details />
      </v-col>
      <v-col cols="12" class="px-0 py-1">
        <v-row>
          <v-col cols="12" class="pb-1">
            <div :class="['d-flex', 'align-center', 'kbGray', 'rounded-t-lg', !enabledFilters.events && 'rounded-b-lg']">
              <v-col class="flex-shrink-1 flex-grow-0">
                <v-img
                  src="https://storage.googleapis.com/keybe/kb.live/img/filters-events.svg"
                  width="25px"
                  class="ml-4"
                ></v-img>
              </v-col>
              <v-col class="pl-0">
                <h2 class="filters-title"
                    :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#8898aa'}">
                  {{ $t('events') | uppercase() }}
                </h2>
              </v-col>
              <v-col class="flex-shrink-1 flex-grow-0">
                <v-switch
                  v-model="enabledFilters.events"
                  @change="checkVenueChildren('events', enabledFilters.events)"
                  color="kbBlue" inset
                ></v-switch>
              </v-col>
            </div>
          </v-col>
        </v-row>
        <template v-if="enabledFilters.events && eventsCategories.length">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-list class="rounded-b-lg py-0">
                <template v-for="(eventCategory, i) in eventsCategories">
                  <template v-if="eventCategory.preferences && Array.isArray(eventCategory.preferences)">
                    <v-list-group
                      :key="i"
                      :class="[!eventCategory.preferences.length && 'd-none-icon']"
                      sub-group no-action
                    >
                      <template v-slot:activator class="filters-option">
                        <v-list-item-content>
                          <v-list-item-title
                            class="filters-label"
                            :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#8898aa'}">
                            {{eventCategory.name}}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-sheet>
                          <v-switch
                            v-model="eventsCategoriesFilters[eventCategory.id].active"
                            @change="checkCategoryChildrenParent('events', i, eventsCategoriesFilters[eventCategory.id].active)"
                            color="kbBlue" inset
                          ></v-switch>
                        </v-sheet>
                      </template>
                      <v-list-item v-for="(preference, j) in eventCategory.preferences" :key="j">
                        <v-list-item-title v-text="preference.label"></v-list-item-title>
                        <v-list-item-action>
                          <v-sheet>
                            <v-switch
                              v-model="eventsCategoriesFilters[eventCategory.id].preferences[preference._id].active"
                              @change="checkPreferenceParent('events', i, j, eventsCategoriesFilters[eventCategory.id].preferences[preference._id].active)"
                              color="kbBlue"
                              inset
                            ></v-switch>
                          </v-sheet>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-group>
                  </template>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </template>
      </v-col>
      <v-col cols="12" class="px-0 py-1">
        <v-row>
          <v-col cols="12" class="pb-1">
            <div :class="['d-flex', 'align-center', 'kbGray', 'rounded-t-lg', !enabledFilters.places && 'rounded-b-lg']">
              <v-col class="flex-shrink-1 flex-grow-0">
                <v-img
                  src="https://storage.googleapis.com/keybe/kb.live/img/filters-places.svg"
                  width="20px"
                  class="ml-4"
                ></v-img>
              </v-col>
              <v-col class="pl-0">
                <h2 class="filters-title"
                    :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#8898aa'}">
                  {{ $t('places') | uppercase() }}
                </h2>
              </v-col>
              <v-col class="flex-shrink-1 flex-grow-0">
                <v-switch
                  v-model="enabledFilters.places"
                  @change="checkVenueChildren('places', enabledFilters.places)"
                  color="kbBlue" inset
                ></v-switch>
              </v-col>
            </div>
          </v-col>
        </v-row>
        <template v-if="enabledFilters.places && placesCategories.length">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-list class="rounded-b-lg py-0">
                <template v-for="(placeCategory, i) in placesCategories">
                  <template v-if="placeCategory.preferences && Array.isArray(placeCategory.preferences)">
                    <v-list-group
                      :key="i"
                      :class="[!placeCategory.preferences.length && 'd-none-icon']"
                      sub-group no-action
                    >
                      <template v-slot:activator class="filters-option">
                        <v-list-item-content>
                          <v-list-item-title
                            class="filters-label"
                            :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#8898aa'}">
                            {{placeCategory.name}}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-sheet>
                          <v-switch
                            v-model="placesCategoriesFilters[placeCategory.id].active"
                            @change="checkCategoryChildrenParent('places', i, placesCategoriesFilters[placeCategory.id].active)"
                            color="kbBlue" inset
                          ></v-switch>
                        </v-sheet>
                      </template>
                      <v-list-item v-for="(preference, j) in placeCategory.preferences" :key="j">
                        <v-list-item-title v-text="preference.label"></v-list-item-title>
                        <v-list-item-action>
                          <v-sheet>
                            <v-switch
                              v-model="placesCategoriesFilters[placeCategory.id].preferences[preference._id].active"
                              @change="checkPreferenceParent('places', i, j, placesCategoriesFilters[placeCategory.id].preferences[preference._id].active)"
                              color="kbBlue"
                              inset
                            ></v-switch>
                          </v-sheet>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-group>
                  </template>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </template>
      </v-col>
      <v-col cols="12" class="px-0 py-1">
        <v-row>
          <v-col cols="12" class="pb-1">
            <div :class="['d-flex', 'align-center', 'kbGray', 'rounded-t-lg', 'rounded-b-lg']">
              <v-col class="flex-shrink-1 flex-grow-0">
                <v-img
                  src="https://storage.googleapis.com/keybe/kb.live/img/filters-restaurants.svg"
                  width="25px"
                  class="ml-4"
                ></v-img>
              </v-col>
              <v-col class="pl-0">
                <h2 class="filters-title"
                    :style="{'color': $vuetify.theme.dark ? '#ffffff' : '#8898aa'}">
                  {{ $t('restaurants') | uppercase() }}
                </h2>
              </v-col>
              <v-col class="flex-shrink-1 flex-grow-0">
                <v-switch
                  v-model="enabledFilters.restaurants"
                  color="kbBlue" inset
                ></v-switch>
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="filter-floating-actions">
      <v-btn
        class="confirm-filter-btn"
        fab
        dark
        large
        color="kbBlue"
        @click="confirmFilters"
      >
        <v-icon dark>mdi-check-bold</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Filters',
  computed: {
    ...mapState({
      placesCategories: (state) => state.filters.placesCategories,
      eventsCategories: (state) => state.filters.eventsCategories,
      indexOfRestaurantPlaceCategoryId: (state) => state.filters.indexOfRestaurantPlaceCategoryId,
      isLoggedIn: state => state.authKeybe.isLoggedIn
    }),
    enabledFilters: {
      get () {
        return this.$store.state.filters.enabledFilters
      },
      set (value) {
        this.$store.commit('filters/SET_ENABLED_FILTERS', value)
      }
    },
    eventsCategoriesFilters: {
      get () {
        return this.$store.state.filters.eventsCategoriesFilters
      },
      set (value) {
        this.$store.commit('filters/SET_EVENTS_CATEGORIES_FILTERS', value)
      }
    },
    placesCategoriesFilters: {
      get () {
        return this.$store.state.filters.placesCategoriesFilters
      },
      set (value) {
        this.$store.commit('filters/SET_PLACES_CATEGORIES_FILTERS', value)
      }
    },
    formattedEventsFilter: {
      get () {
        return this.$store.state.filters.formattedEventsFilter
      },
      set (value) {
        this.$store.commit('filters/SET_FORMATTED_EVENTS_FILTER', value)
      }
    },
    formattedPlacesFilter: {
      get () {
        return this.$store.state.filters.placesCategoriesFilters
      },
      set (value) {
        this.$store.commit('filters/SET_FORMATTED_PLACES_FILTER', value)
      }
    }
  },
  data () {
    return {}
  },
  async created () {
    this.$store
      .dispatch('filters/getEventsCategories')
      .catch(e => { console.error(e) })
    this.$store
      .dispatch('filters/getPlacesCategories')
      .catch(e => { console.error(e) })
  },
  methods: {
    checkVenueChildren (venueType, value) {
      // toggleVenueChildren:
      // console.log(venueType, value)
      if (value) {
        if (venueType === 'events') {
          for (let i = 0; i < this.eventsCategories.length; i++) {
            const eventCategory = this.eventsCategories[i]
            this.$set(this.eventsCategoriesFilters[eventCategory.id], 'active', value)
          }
        }
        if (venueType === 'places') {
          for (let i = 0; i < this.placesCategories.length; i++) {
            const placeCategory = this.placesCategories[i]
            this.$set(this.placesCategoriesFilters[placeCategory.id], 'active', value)
          }
        }
      }
    },
    checkCategoryChildrenParent (categoryType, categoryIdx, value) {
      // console.log('categoryType, value')
      // console.log(categoryType, value)
      // Set all children to {value}
      if (categoryType === 'events') {
        // toggleCategoryChildren:
        const eventCategory = this.eventsCategories[categoryIdx]
        let eventsPreferences = eventCategory.preferences
        for (let i = 0; i < eventsPreferences.length; i++) {
          const eventPreference = eventsPreferences[i]
          this.$set(this.eventsCategoriesFilters[eventCategory.id].preferences[eventPreference._id], 'active', value)
        }
      }
      if (categoryType === 'places') {
        // toggleCategoryChildren:
        const placeCategory = this.placesCategories[categoryIdx]
        let placesPreferences = placeCategory.preferences
        for (let i = 0; i < placesPreferences.length; i++) {
          const placePreference = placesPreferences[i]
          this.$set(this.placesCategoriesFilters[placeCategory.id].preferences[placePreference._id], 'active', value)
        }
      }
      // Check if its necessary to uncheck parent
      if (!value) {
        if (categoryType === 'events') {
          let areAllItemsDisabled = true
          for (const categoryId in this.eventsCategoriesFilters) {
            // console.log(this.eventsCategoriesFilters[categoryId].active)
            if (this.eventsCategoriesFilters[categoryId].active) areAllItemsDisabled = false
          }
          if (areAllItemsDisabled) this.enabledFilters.events = false
        }
        if (categoryType === 'places') {
          let areAllItemsDisabled = true
          for (const categoryId in this.placesCategoriesFilters) {
            // console.log(this.placesCategoriesFilters[categoryId].active)
            if (this.placesCategoriesFilters[categoryId].active) areAllItemsDisabled = false
          }
          if (areAllItemsDisabled) this.enabledFilters.places = false
        }
      }
    },
    checkPreferenceParent (categoryType, categoryIdx, preferenceIdx, value) {
      // Check if its necessary to enable parent
      // If Active, and parent is disabled, toggle parent to true
      if (value) {
        if (categoryType === 'events') {
          const eventCategory = this.eventsCategories[categoryIdx]
          if (!this.eventsCategoriesFilters[eventCategory.id].active) {
            this.$set(this.eventsCategoriesFilters[eventCategory.id], 'active', true)
          }
        }
        if (categoryType === 'places') {
          const placeCategory = this.placesCategories[categoryIdx]
          if (!this.placesCategoriesFilters[placeCategory.id].active) {
            this.$set(this.placesCategoriesFilters[placeCategory.id], 'active', true)
          }
        }
      } else {
        if (categoryType === 'events') {
          let areAllItemsDisabled = true
          const eventCategory = this.eventsCategories[categoryIdx]
          let eventsPreferences = eventCategory.preferences
          for (let i = 0; i < eventsPreferences.length; i++) {
            const eventPreference = eventsPreferences[i]
            if (this.eventsCategoriesFilters[eventCategory.id].preferences[eventPreference._id].active) {
              areAllItemsDisabled = false
            }
          }
          if (areAllItemsDisabled) {
            this.$set(this.eventsCategoriesFilters[eventCategory.id], 'active', false)
            this.checkCategoryChildrenParent(categoryType, categoryIdx, false)
          }
        }
        if (categoryType === 'places') {
          let areAllItemsDisabled = true
          const placeCategory = this.placesCategories[categoryIdx]
          let placesPreferences = placeCategory.preferences
          for (let i = 0; i < placesPreferences.length; i++) {
            const placePreference = placesPreferences[i]
            if (this.placesCategoriesFilters[placeCategory.id].preferences[placePreference._id].active) {
              areAllItemsDisabled = false
            }
          }
          if (areAllItemsDisabled) {
            this.$set(this.placesCategoriesFilters[placeCategory.id], 'active', false)
            this.checkCategoryChildrenParent(categoryType, categoryIdx, false)
          }
        }
      }
    },
    async confirmFilters () {
      const placesFilters = {
        filters: {
          and: [],
          or: [
            // {
            //   and: []
            // }
          ]
        }
      }
      const eventsFilters = {
        filters: {
          and: [],
          or: [
            // {
            //   and: []
            // }
          ]
        }
      }
      // if switch is enabled
      if (this.enabledFilters.places) {
        // get the categories ids in each key
        for (const key in this.placesCategoriesFilters) {
          const and = []
          if (this.placesCategoriesFilters[key] && this.placesCategoriesFilters[key].active) {
            and.push({
              field: 'categories',
              compareType: 'eq',
              compareValue: key
            })
            const preferences = []
            // get the preferences ids in each key
            for (const prefKey in this.placesCategoriesFilters[key].preferences) {
              this.placesCategoriesFilters[key].preferences[prefKey].active && preferences.push(
                this.placesCategoriesFilters[key].preferences[prefKey].preferenceId
              )
            }
            preferences.length && and.push({
              field: 'preferences',
              compareType: 'in',
              compareValue: preferences
            })
          }
          and.length && placesFilters.filters.or.push({ and })
        }
      }
      if (this.enabledFilters.events) {
        // get the categories ids in each key
        for (const key in this.eventsCategoriesFilters) {
          const and = []
          if (this.eventsCategoriesFilters[key] && this.eventsCategoriesFilters[key].active) {
            and.push({
              field: 'categories',
              compareType: 'eq',
              compareValue: key
            })
            const preferences = []
            // get the preferences ids in each key
            for (const prefKey in this.eventsCategoriesFilters[key].preferences) {
              this.eventsCategoriesFilters[key].preferences[prefKey].active && preferences.push(
                this.eventsCategoriesFilters[key].preferences[prefKey].preferenceId
              )
            }
            preferences.length && and.push({
              field: 'preferences',
              compareType: 'in',
              compareValue: preferences
            })
          }
          and.length && eventsFilters.filters.or.push({ and })
        }
      }
      if (this.enabledFilters.restaurants) {
        // get the categories ids in each key
        const key = this.indexOfRestaurantPlaceCategoryId
        const and = []
        and.push({
          field: 'categories',
          compareType: 'eq',
          compareValue: key
        })
        const preferences = []
        // get the preferences ids in each key
        // for (const prefKey in this.placesCategoriesFilters[key].preferences) {
        //   this.placesCategoriesFilters[key].preferences[prefKey].active && preferences.push(
        //     this.placesCategoriesFilters[key].preferences[prefKey].preferenceId
        //   )
        // }
        preferences.length && and.push({
          field: 'preferences',
          compareType: 'in',
          compareValue: preferences
        })
        and.length && placesFilters.filters.or.push({ and })
      }
      this.formattedPlacesFilter = placesFilters
      this.formattedEventsFilter = eventsFilters
      if (this.isLoggedIn) {
        // Store filters
        await this.$store.dispatch('authKeybe/updateUser', {
          extradata: [
            { field: 'enabledFilters', value: this.enabledFilters },
            { field: 'placesCategoriesFilters', value: this.placesCategoriesFilters },
            { field: 'eventsCategoriesFilters', value: this.eventsCategoriesFilters },
            { field: 'formattedEventsFilter', value: this.formattedEventsFilter },
            { field: 'formattedPlacesFilter', value: this.formattedPlacesFilter }
          ]
        })
      }
      this.$router.push({ name: 'Home' })
      this.$store.dispatch('events/getEvents')
      this.$store.dispatch('places/getPlaces')
    }
  }
}
</script>
<style scoped lang="scss">
.filters {
  margin: auto;
}

.filters-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters-title {
  font-size: 22px;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 10px;
}

.filters-label {
  font-size: 22px;
  text-align: left;
  height: 27px;
}

.filter-floating-actions {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  .confirm-filter-btn {
    width: 75px;
    height: 75px;
    padding: 2.5em;
  }
}
@media only screen and (min-width: 768px) {
  .filter-floating-actions {
    right: calc((100vw - 768px) / 2 + 10px);
  }
}
</style>
<style>
.d-none-icon .v-list-item__icon.v-list-group__header__prepend-icon {
  display: none;
}

</style>
